.MuiSelect-icon {
	display: block;
	width: 22px;
	height: 20px;
}

.MuiSelect-icon.Mui-disabled {
	display: none;
}

.MuiMenuItem-root {
	margin-bottom: 10px;
}

.MuiMenuItem-root:last-child {
	margin-bottom: 0;
}

.MuiAutocomplete-inputRoot {
	height: auto;
}

.MuiAutocomplete-input {
	margin-top: -4.5px;
}

.Mui-disabled {
	-webkit-text-fill-color: rgb(0, 0, 0) !important;
}

.MuiChip-root {
	color: #fff;
}

.date-input {
	width: 100%;
	height: 45px;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 0.375rem;
	padding: 10px;
	font-size: 0.875rem;
	outline-color: #2a5fd1;
}

.short-text {
	width: 120px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.icon-image {
	width: 20px;
}

.list-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.list-item p,
.label {
	font-size: 0.875rem;
	color: #7b809a;
	padding: 6px 0;
}
